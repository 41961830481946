.metric-change {
	&.no-change {
	}
	.original-value {
	}
	.m-change {
		font-size: 0.4em;
		margin-left: 4px;
		&.asc {
		}

		&.desc {
		}
	}
	&.table-rating {
		.m-change {
			font-size: 0.9em;
			margin-left: 4px;
			&.asc {
				color: rgb(0, 150, 0);
			}

			&.desc {
				color: rgb(189, 0, 0);
			}
		}
	}
}
