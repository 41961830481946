@import '../../mixins.scss';
.plates {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
	.magic-plate {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: flex-start;
		transition: box-shadow 0.1s, transform 0.1s;
		border-radius: 20px;
		height: 140px;
		color: white;
		flex-grow: 1;
		flex-basis: 0;
		min-width: 200px;
		padding: 20px 30px;
		&:hover {
			box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
			cursor: pointer;
		}
		& * {
			pointer-events: none;
		}
		&:not(:last-child) {
			margin-right: 30px;
		}
		&.red {
			background: linear-gradient(89.69deg, #f39034 2.03%, #ff2727 99.73%);
		}
		&.blue {
			background: linear-gradient(270deg, #003ad2 0%, #0097ec 100%);
		}
		&.green {
			background: linear-gradient(270.23deg, #00a843 0.19%, #1fd071 99.8%);
		}
		.plate-head {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			.plate-title {
				font-size: 14px;
				font-weight: bold;
			}
		}
		.plate-body {
			font-size: 42px;
			font-weight: 500;
			line-height: 42px;
			margin-bottom: 3px;
		}
		.plate-footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.plate-link {
				font-size: 14px;
			}
		}
	}
	@include mobile {
		flex-direction: column;
		align-items: stretch;
		justify-content: flex-start;
		margin-top: 10px;
		.magic-plate {
			margin-bottom: 10px;
			&:not(:last-child) {
				margin-right: 0;
			}
		}
	}
}

.employee-plate {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
	background: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
	padding: 20px;
}

.rating-chart {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	flex-grow: 1;
	border-radius: 20px;
	background: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
	padding: 20px;
	@include mobile {
		margin-left: 0px;
		margin-top: 20px;
	}
}
