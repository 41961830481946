@import '../../mixins.scss';

.TPage {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	@include mobile {
		flex-direction: column;
		max-width: 98vw;
	}
}

.TPSidebar {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	flex-basis: 200px;
	width: 200px;
	border-right: 1px solid #f0f0f0;
	@include mobile {
		flex-basis: auto;
		width: auto;
		border-right: 0;
		border-bottom: 1px solid #f0f0f0;
		margin-bottom: 20px;
	}
	.ant-menu {
		width: 200px;
		@include mobile {
			width: auto;
		}
	}
}

.TPSidebarHeader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 30px;
	border-bottom: 1px solid #f0f0f0;
}

.TPSidebarItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-basis: 40px;
	border-bottom: 1px solid #f0f0f0;
}

.TPContent {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	flex-grow: 1;
	flex-shrink: 1;
}
