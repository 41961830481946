@import '../../mixins.scss';
.StatHeader {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	margin-bottom: 15px;
	width: 100%;
	border-bottom: 1px solid #f0f0f0;
	@include mobile {
		flex-direction: column;
		justify-content: flex-start;
	}
}

.StatPlate {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	flex-grow: 1;
	@include mobile {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

.StatDiv {
	height: 100px;
	width: 1px;
	background-color: #f0f0f0;
	@include mobile {
		height: auto;
	}
}

.StatPlateTitle {
	font-size: 14px;
	margin-bottom: 10px;
	text-align: center;
}

.StatPlateValue {
	font-size: 32px;
	font-weight: bold;
	text-align: center;
}
