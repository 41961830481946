.login-page {
    background-color: #363740;
    align-items: center;
    justify-content: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 380px;
    height: 420px;
    &.open {
        height: 470px;
    }
    &.done {
        overflow: visible;
    }
    transition: all 0.4s;
    overflow: hidden;
    perspective: 1000px;
}

.login-form-better {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    &.done {
        overflow: visible;
    }
    transition: all 0.4s;
    overflow: hidden;
    perspective: 1000px;
    .login-form-inner {
        padding: 20px;
    }
}

.demo-acc-plate {
    padding: 10px;
    display: flex;
    flex-direction: row;
    background-color: #e8e8e8;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
        background-color: #d0d0d0;
    }
}

.login-form.done .login-form-inner {
    transform: rotateY(180deg);
}

.flying-avatar {
    transition: transform 0.4s, left 0.4s, top 0.4s;
    transform-origin: left top;
    &.expanding {
        transform: scale(0.353846);
    }
}

.login-form.done.expanding {
    width: 100vw;
    height: 100vh;
    .login-form-inner {
        border-radius: 0px;
    }
    .login-back {
        h2,
        h3 {
            opacity: 0;
        }
    }
}

.login-back {
    h2,
    h3 {
        opacity: 1;
        transition: opacity 0.4s;
    }
}

.login-front,
.login-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}


/* Style the back side */

.login-back {
    transform: rotateY(180deg);
}

.login-form-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
    background: #ffffff;
    border: 1px solid #dfe0eb;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 1;
    transition: border-radius 0.4s, transform 0.8s;
    .l-maintitle {
        font-size: 14px;
        color: black;
        text-align: center;
        margin-top: 52px;
    }
    .l-subtitle {
        font-size: 14px;
        color: #9fa2b4;
        text-align: center;
        margin-top: 52px;
    }
    .login-form-content {
        margin-top: 48px;
        margin-bottom: 20px;
        .bp3-form-group {
            margin-bottom: 20px;
        }
    }
}

.login-logo {
    width: 305px;
    max-height: 120px;
    align-self: center;
    margin-top: 24px;
}

.login-sublogo {
    width: 251px;
    align-self: center;
    margin-top: 10px;
}