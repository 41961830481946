@import '../../mixins.scss';
.main-page {
	background-color: #f7f8fc;
	flex-direction: row;
	align-items: stretch;
}

.sidebar {
	background-color: #363740;
	flex-basis: 209px;
	flex-shrink: 0;
	.sb-logo {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		width: 160px;
		align-self: center;
		margin-top: 30px;
		margin-bottom: 40px;
	}
	.sidebar-content {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: flex-start;
		background-color: #ffffff;
		min-height: 100vh;
		.sidebar-gap {
			flex-grow: 1;
			border-bottom: 1px solid #e0e0e0;
		}
		.sidebar-item {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
			position: relative;
			padding-left: 15px;
			height: 50px;
			color: rgba(31, 65, 115, 0.5);
			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
			.sidebar-item-border {
				position: absolute;
				left: 0px;
				top: 14px;
				bottom: 14px;
				width: 4px;
				background-color: transparent;
			}
			&.sidebar-active {
				color: #003ad2;
			}
			&.sidebar-active .sidebar-item-border {
				border-left: 3px solid #003ad2;
			}
			.sidebar-item-wrap {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				height: 50px;
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;
				padding-top: 18px;
				padding-bottom: 18px;
				padding-left: 20px;
				span {
					transform-origin: left center;
					transition: transform 0.2s;
				}
				.bp3-icon {
					margin-right: 10px;
					opacity: 0.7;
					transform-origin: center;
				}
			}
			&:hover {
				text-decoration: none;
				&:not(.sidebar-active) .bp3-icon {
					transform: scale(1.3);
					opacity: 0.8;
				}
			}
			&.sidebar-active .sidebar-item-wrap {
				background-color: rgba(0, 0, 0, 0.08);
				.bp3-icon {
					opacity: 1;
				}
			}
		}
	}
}

.content {
	flex-grow: 1;
	flex-shrink: 1;
	background-color: #f7f8fc;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
}

.layout {
	flex-grow: 1;
	flex-shrink: 1;
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	@include mobile {
		padding: 4px;
	}
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 70px;
	background-color: white;
	padding-left: 30px;
	padding-right: 30px;
	@include mobile {
		padding-left: 10px;
		padding-right: 10px;
	}
	.header-left {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.header-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		.nots {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding-right: 30px;
			margin-right: 40px;
			height: 30px;
			border-right: 1px solid #dfe0eb;
			.nots-inner {
				position: relative;
			}
			.emphaizer {
				position: absolute;
				right: -2px;
				top: -2px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #ffdc00;
				border: 2px solid #f7f8fc;
			}
		}
		.profile {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			padding: 5px 10px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
			}
			.user-name {
				margin-right: 15px;
				font-size: 14px;
				text-align: right;
			}
			.avatar-wrap {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				width: 48px;
				height: 48px;
				border-radius: 50%;
				border: 1.5px solid #dfe0eb;
				.avatar {
					width: 45px;
					height: 45px;
					border-radius: 50%;
					border: 1.5px solid white;
				}
			}
		}
	}
}

.section {
	margin-top: 30px;
	background-color: white;
	border-radius: 8px;
	border: 1px solid #dfe0eb;
}

.not {
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0px;
	}
}

h3.ant-typography {
	margin-bottom: 0;
}

.page {
	opacity: 1;
	transition: opacity 0.5s;
	&.hidden {
		opacity: 0;
	}
	.page-head {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		margin-top: 10px;
		margin-bottom: 20px;

		@include mobile {
			flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
			padding: 5px;
		}
		.page-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 5px;
			h2,
			h3,
			h4 {
				font-weight: 400;
				margin-bottom: 0;
				margin-left: 4px;
			}
		}
		.page-actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			& > *:not(:first-child) {
				margin-left: 12px;
			}
			@include mobile {
				flex-direction: column;
				align-items: stretch;
				justify-content: flex-start;
				& > *:not(:first-child) {
					margin-top: 8px;
					margin-left: 0;
				}
			}
		}
	}
}

.section {
	background: #ffffff;
	box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
	border: 0;
	border-radius: 20px;
	padding: 20px;
	margin-top: 15px;
	@include mobile {
		padding: 5px;
		margin-top: 9px;
	}
}

.card-section {
	background: #ffffff;
	box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
	border: 0;
	border-radius: 7px;
	overflow: hidden;
}

.ant-dropdown-menu-item {
	.-title-content {
		width: 100%;
	}
}

.avatar-places-dropdown.ant-avatar {
	& > img {
		width: 22px;
		height: 22px;
		object-fit: cover;
		aspect-ratio: 1/1;
	}
}

.tpb {
	position: absolute;

	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-size: 11px;
	line-height: 100%;
	color: #303030;

	right: 2px;
	top: 4px;

	padding-top: 1px;
	padding-left: 3px;
	padding-right: 3px;

	// background-color: #dff0fc;
	background-image: linear-gradient(
		109.6deg,
		rgba(0, 181, 242, 0.5) 11.2%,
		rgba(128, 255, 221, 0.5) 57.8%,
		rgba(255, 128, 249, 0.5) 85.9%
	);
	border-radius: 6px;
	padding-top: 2px;

	transform: scale(0.9, 0.9);

	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0);

	transition: all 0.3s;
	cursor: pointer;
	pointer-events: all;

	&:hover {
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
	}

	.tpbb {
		margin-bottom: -1px;
		font-size: 10px;
	}
}

.tipzpro {
	display: inline-flex;

	position: relative;
	overflow: hidden;

	border-radius: 6px;
	padding: 4px 5px;
	font-weight: bold;
	// text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.3);

	border: 0px solid white;
	// background-image: linear-gradient(
	// 	109.6deg,
	// 	rgba(0, 181, 242, 0.4) 11.2%,
	// 	rgba(128, 255, 221, 0.4) 57.8%,
	// 	rgba(255, 128, 249, 0.4) 85.9%
	// );
	color: black;

	&.shine {
		&::after {
			content: '';
			top: 0;
			transform: translateX(100%);
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			animation: slide 5s infinite 3s;

			/*
		CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
		*/
			background: -moz-linear-gradient(
				left,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.8) 50%,
				rgba(128, 186, 232, 0) 99%,
				rgba(125, 185, 232, 0) 100%
			); /* FF3.6+ */
			background: -webkit-gradient(
				linear,
				left top,
				right top,
				color-stop(0%, rgba(255, 255, 255, 0)),
				color-stop(50%, rgba(255, 255, 255, 0.8)),
				color-stop(99%, rgba(128, 186, 232, 0)),
				color-stop(100%, rgba(125, 185, 232, 0))
			); /* Chrome,Safari4+ */
			background: -webkit-linear-gradient(
				left,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.8) 50%,
				rgba(128, 186, 232, 0) 99%,
				rgba(125, 185, 232, 0) 100%
			); /* Chrome10+,Safari5.1+ */
			background: -o-linear-gradient(
				left,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.8) 50%,
				rgba(128, 186, 232, 0) 99%,
				rgba(125, 185, 232, 0) 100%
			); /* Opera 11.10+ */
			background: -ms-linear-gradient(
				left,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.8) 50%,
				rgba(128, 186, 232, 0) 99%,
				rgba(125, 185, 232, 0) 100%
			); /* IE10+ */
			background: linear-gradient(
				to right,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.8) 50%,
				rgba(128, 186, 232, 0) 99%,
				rgba(125, 185, 232, 0) 100%
			); /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
		}
	}
}

@keyframes slide {
	0% {
		transform: translateX(-100%);
	}
	40% {
		transform: translateX(-100%);
	}
	60% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(100%);
	}
}
