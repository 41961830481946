.bp3-button.tipz-button {
	box-shadow: 0px 4px 12px rgba(77, 81, 255, 0.24);
	background: #003AD2;
	height: 48px;
	color: white;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 500;

	&:hover {
		box-shadow: 0px 4px 12px rgba(77, 81, 255, 0.24);
		background: #104ce2;
	}

	&.bp3-loading {
		box-shadow: 0px 4px 12px rgba(77, 81, 255, 0.24);
		background: #003AD2;
	}
}