@import '../../mixins.scss';
.table-controller {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    @include mobile {
        overflow: scroll;
        max-width: 96vw;
    }
    .ant-pagination {
        align-self: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}