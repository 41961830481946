.fullpage {
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	&.fullpage-center {
		align-items: center;
		justify-content: center;
	}
}

.bp3-form-group {
	.bp3-label {
		font-size: 12px;
		font-weight: bold;
		color: #9fa2b4;
		text-transform: uppercase;
	}
	.bp3-input-group input {
		font-size: 14px;
		&::placeholder {
			color: rgba(75, 80, 109, 0.4);
		}
	}
}

.bp3-input-group {
	input {
		background-color: #fcfdfe;
		border-radius: 8px;
		height: 42px;
		border: 1px solid #f0f1f7;
		box-shadow: none;
	}
	.bp3-input-action {
		margin-top: 6px;
		margin-right: 6px;
	}
}
