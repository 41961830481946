table.bp3-html-table.tipz-table {
	width: 100%;
	td {
		vertical-align: middle;
	}
}

.tipz-table-wrap {
	position: relative;
	th,
	td {
		padding: 6px 10px;
	}
	.tipz-table.bp3-interactive tbody tr {
		&:hover {
			background-color: #f4f4f4;
			cursor: pointer;
		}
	}
	.tipz-table {
		width: 100%;
		thead tr th {
			text-align: left;
			font-size: 13px;
			padding-bottom: 10px;
			&.sortable {
				cursor: pointer;
			}
		}
		thead tr th,
		tbody tr td {
			&.cell-center {
				text-align: center;
			}
		}
		tbody tr.row-selected td {
			background-color: rgb(198, 225, 255);
		}
	}
	.tipz-table-overlay {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 5;
		background-color: rgba(255, 255, 255, 0.7);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
